import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceGatewaysPage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Gateways"
          description="View route information and buy your ticket for the Gateways service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">Gateways service</h1>
              <p>Old Lane (Tommy Wass) - Gateways School</p>
              <p>Gateways School - Old Lane (Tommy Wass)</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
              <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="Gateways AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1iZOC_VCvQ_cKjRKCGUn5xeCrUYjEGJo&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Gateways School
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="Gateways PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1KRmnXH8383nqqejGpOkzNRVgKLxgK90&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Gateways School
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Old Lane (Asda Beeston)</td>
                      <td>DEP</td>
                      <td>07:10</td>
                    </tr>
                    <tr>
                      <td>Scott Hall road (Scott Hall Av)</td>
                      <td>DEP</td>
                      <td>07:28</td>
                    </tr>
                    <tr>
                      <td>Stainbeck Ln (Wensley Drive)</td>
                      <td>DEP</td>
                      <td>07:32</td>
                    </tr>
                    <tr>
                      <td>Harrogate Road (Aldi)</td>
                      <td>DEP</td>
                      <td>07:34</td>
                    </tr>
                    <tr>
                      <td>Harrogate Rd (Wood Lane)</td>
                      <td>DEP</td>
                      <td>07:35</td>
                    </tr>
                    <tr>
                      <td>Harrogate Rd (King Lane)</td>
                      <td>DEP</td>
                      <td>07:37</td>
                    </tr>
                    <tr>
                      <td>Lidgett Lane (Allerton Grange Av)</td>
                      <td>DEP</td>
                      <td>07:41</td>
                    </tr>
                    <tr>
                      <td>Brackenwood Drive (Larkhill Rd)</td>
                      <td>DEP</td>
                      <td>07:42</td>
                    </tr>
                    <tr>
                      <td>Brackenwood Dr (Larkhill View)</td>
                      <td>DEP</td>
                      <td>07:43</td>
                    </tr>
                    <tr>
                      <td>Lidgett Lane (Gledhow PS)</td>
                      <td>DEP</td>
                      <td>07:47</td>
                    </tr>
                    <tr>
                      <td>Lidgett Park Av at The Avenue (No Bus stop)</td>
                      <td>DEP</td>
                      <td>07:50</td>
                    </tr>
                    <tr>
                      <td>Street Ln - Park Lane</td>
                      <td>DEP</td>
                      <td>07:52</td>
                    </tr>
                    <tr>
                      <td>Street Ln - Devonshire Avenue</td>
                      <td>DEP</td>
                      <td>07:55</td>
                    </tr>
                    <tr>
                      <td>Street Ln - Talbot Road</td>
                      <td>DEP</td>
                      <td>07:57</td>
                    </tr>
                    <tr>
                      <td>Gateways School</td>
                      <td>ARR</td>
                      <td>08:30</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr><td>Gateways School (Arrive 15:50)</td><td>DEP</td><td>16:10</td></tr>
                  <tr><td>Street Ln - Talbot grove</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Street Ln - Devonshire Avenue</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Lidgett Park Av at The Avenue (No Bus stop)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Lidgett Lane (Gledhow PS)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Brackenwood Drive (Larkhill View)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Brackenwood Drive (Larkhill Rd)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Lidgett Ln - Allerton Grange Av</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Harrogate Road (King Lane)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Harrogate Road (Wood Lane)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Harrogate Road (Aldi)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Stainbeck Lane (Wensley drive)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Scott Hall Road (Scott Hall Av) B</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Old Lane (Asda Beeston)</td><td>DEP</td><td>-</td></tr>
                  <tr><td>Old Lane (Tommy Wass)</td><td>ARR</td><td>-</td></tr>
                  </tbody>
                </table>

                {/* <MetroFares /> */}
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceGatewaysPage
